import React, {useMemo, useRef, useState} from 'react';
import {Autocomplete, Box, Button, Container, FormControl, Paper, Stack, TextField, Typography} from '@mui/material';
import {useMutation, useQueryClient} from "react-query";
import {postCreateRegistry} from "../api";
import {EmbeddedCheckout, EmbeddedCheckoutProvider} from '@stripe/react-stripe-js';
import {useStripePromise} from "../hooks/useStripePromise";
import {Label} from "@mui/icons-material";

type OptionType = {
    label: string;
    value: string;
}

export const CreateRegistry = () => {
    const [name, setName] = useState('')
    const [clientSecret, setClientSecret] = useState('')
    const [invalidNames, setInvalidNames] = useState<Array<string>>([])

    const queryClient = useQueryClient();
    const stripePromise = useStripePromise();

    const options: Array<OptionType> = [
        {
            label: 'Free Plan',
            value: 'free'
        },
        {
            label: 'Paid Plan',
            value: 'paid'
        }];


    const createRegistryMutation = useMutation({
        mutationFn: async ({name, plan}: { name: string, plan: string }) => postCreateRegistry(name, plan),
        onSuccess: (data) => {
            setClientSecret(data.checkoutClientSecret)
        },
        onError: (err, variables) => {
            if ((err as Error).message === 'Conflict') { // Registry name already exists
                if (invalidNames.indexOf(variables.name) > -1) return

                setInvalidNames([...invalidNames, variables.name])
            }
        }
    })

    const onCreateClicked = () => createRegistryMutation.mutate({name, plan: 'free'})

    const nameAlreadyInUse =  useMemo(() => invalidNames.includes(name), [invalidNames, name])
    const hasCapitalLetters = name.toLowerCase() !== name
    const disableRegistryCreation = nameAlreadyInUse || hasCapitalLetters

    return !clientSecret ?
        <Container sx={{height: '100%', margin: 'auto'}}>
            <Box sx={{}}>
                <Paper sx={{margin: 'auto', padding: 5, textAlign: 'center', height: "100%", borderRadius: 2}}
                       elevation={10}>
                    <Stack>
                        <Typography variant='h4' gutterBottom>Create a new PrivateUPM Registry</Typography>
                        <FormControl sx={{p: 1}}>
                            <TextField
                                error={nameAlreadyInUse || hasCapitalLetters}
                                label='Name'
                                placeholder={"Enter registry name"}
                                value={name}
                                helperText={nameAlreadyInUse ? 'Name already in use. Please choose a different name for your registry' : 'Registry names should be lowercase with no spaces.'}
                                onChange={(e) => setName(e.target.value)}/>
                        </FormControl>
                        <FormControl sx={{p: 1}}>
                            <Autocomplete
                                disablePortal
                                options={options}
                                renderOption={(props, option) =>
                                    <Box component='li' {...props} >{option.label}</Box>
                                }
                                autoHighlight
                                selectOnFocus
                                handleHomeEndKeys
                                renderInput={(params) => <TextField {...params} label={"Choose a plan"}/>}/>
                        </FormControl>

                        <Typography>$0 per month</Typography>
                        <Stack direction='row' sx={{justifyContent: 'space-between'}}>
                            <Button variant='contained'
                                    color='success'
                                    sx={{mt: 2, borderRadius: 0}}
                                    disabled={disableRegistryCreation}
                                    onClick={() => onCreateClicked()}>Create</Button>
                        </Stack>
                    </Stack>
                </Paper>
            </Box>
        </Container>
        :
        <Paper sx={{margin: 'auto', padding: 5, backgroundColor: '#20358d', borderRadius: 2, width: '50%'}}
               elevation={10}>
            <EmbeddedCheckoutProvider stripe={stripePromise} options={{
                clientSecret, onComplete: () => {
                    setTimeout(() => queryClient.invalidateQueries({
                        queryKey: 'dashboard'
                    }), 2000)
                }
            }}>
                <EmbeddedCheckout/>
            </EmbeddedCheckoutProvider>
        </Paper>

}

