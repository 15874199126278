import React, { useState} from 'react';
import {Formik, Form, Field} from 'formik';
import {TextField} from 'formik-mui';
import * as Yup from 'yup';
import {
    Stack,
    Typography,
    Button,
    FormGroup,
    InputAdornment, LinearProgress,
} from "@mui/material";
import {Check, Email, ForwardToInbox} from "@mui/icons-material";
import Turnstile from "react-turnstile";
import {useMutation} from "react-query";

import {postForgotUserPasswordReset, TurnstileSiteKey} from "../api";


const ForgotPasswordSchema = Yup.object().shape({
    email: Yup.string().trim().email().required()
})

export const ForgotPassword = () => {
    const [isEmailSent, setIsEmailSent] = useState(false);
    const [turnstileToken, setTurnstileToken] = useState('')

    const forgotPasswordMutation = useMutation({
        mutationFn: (email: string) => {
            return postForgotUserPasswordReset(turnstileToken, email)
        },
        onSuccess: () => {
            setIsEmailSent(true)
        }
    })


    const ForgotPasswordForm = (
        <Formik
            initialValues={{
                email: ''
            }}
            onSubmit={({email}) => {
                return forgotPasswordMutation.mutate(email)
            }}
            validationSchema={ForgotPasswordSchema}>
            {({
                  submitForm,
                  isValid,
                  dirty,
                  isSubmitting
              }) => (
                <Form>
                    <Typography fontSize='large' variant='h6' fontWeight='bold'>Reset Password</Typography>
                    <Typography fontSize='normal' mb={'20px'}>Please enter your email address to reset your PrivateUPM
                        password</Typography>
                    <FormGroup>
                        <Stack spacing={3} sx={{justifyContent: 'center'}}>
                            <Field
                                component={TextField}
                                name='email'
                                label='Email'
                                placeholder='Enter email address'
                                InputProps={{
                                    startAdornment: <InputAdornment position='start'><Email/></InputAdornment>
                                }}
                            />
                            {isSubmitting && <LinearProgress/>}
                            <Button variant='contained' sx={{
                                borderRadius: '0px',
                                padding: '10px',
                            }} disabled={!turnstileToken || !dirty || !isValid || isSubmitting}
                                    onClick={submitForm}>
                                Send Reset Password Email
                            </Button>
                            <Turnstile sitekey={TurnstileSiteKey}
                                       action={'forgot-password'}
                                       onVerify={token => {
                                           setTurnstileToken(token)
                                       }}/>

                        </Stack>
                    </FormGroup>
                </Form>
            )}
        </Formik>
    )

    const EmailSent = (
        <>
            <Typography fontSize='large' variant='h6' fontWeight='bold' gutterBottom>Password Reset Email
                Sent</Typography>
            <ForwardToInbox fontSize='large' color='success'/>
            <Check fontSize='large' color='success'/>
            <Typography mt='20px' fontSize='normal'>Please click the link sent to your email to reset your
                password.</Typography>
        </>
    )

    return (
        <>
            {isEmailSent ? EmailSent : ForgotPasswordForm}
        </>
    )
}
