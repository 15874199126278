import React, {useContext, useState} from 'react';
import {getAccountDetails, postCreatePortalSession, postResetUserPassword} from "../api";
import {useMutation, useQuery, useQueryClient} from "react-query";
import {v4 as uuidv4} from 'uuid';
import {useNavigate} from "react-router-dom";
import {
    Avatar,
    Box,
    Button,
    CircularProgress,
    Container,
    Divider,
    Paper,
    TextField,
    Typography,
    AppBar, Toolbar, Tooltip, Menu, MenuItem, Stack, IconButton, Switch, FormControlLabel
} from "@mui/material";
import {AccountBox, ArrowBack, Edit, Label} from '@mui/icons-material';
import {AuthContext} from "../contexts/authContext";
import {Tokens} from "../tokens/tokens";
import {SimpleDialog} from "../components/SimpleDialog";

export const Profile = () => {
    const navigate = useNavigate();
    const [editing, setEditing] = useState('');
    const [pendingEmail, setPendingEmail] = useState('');
    const [pendingName, setPendingName] = useState('');
    const [changePasswordDialogOpen, setChangePasswordDialogOpen] = useState(false)
    const accountDetailsQuery = useQuery('accountDetails', getAccountDetails())
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const authContext = useContext(AuthContext);
    const queryClient = useQueryClient();

    const createPortalSessionMutation = useMutation({
        mutationFn: async (idempotency_key: string) => {
            return postCreatePortalSession(uuidv4())
        },
        onSuccess: (res) => {
            window.open(res.url, '_blank')
            // window.location.href = res.url
        }
    });

    const resetPasswordMutation = useMutation({
        mutationFn: () => postResetUserPassword(),
        onSettled: () => setChangePasswordDialogOpen(false)
    })


    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    }

    const handleClose = (clicked: string) => {
        setAnchorEl(null);
        switch (clicked) {
            case 'account':
                navigate('/account')
                return;
            case 'logout':
                authContext.signOut();
                queryClient.clear();
                navigate('/signout')
                break;
        }
    };

    const updateEditing = (editing: string) => {
        setPendingEmail('');
        setPendingName('');
        setEditing(editing);
    }

    if (accountDetailsQuery.isLoading) {
        return <CircularProgress/>
    }


    const editingDisplayName = editing === 'displayName'
    const editingEmail = editing === 'email'

    const details = accountDetailsQuery.data;

    const displayEmail = pendingEmail === '' ? details?.email ?? '' : pendingEmail;
    const displayName = pendingName === '' ? details?.displayName ?? '' : pendingName;
    const editUpdate = false


    const openCustomerPortal = async () => {
        createPortalSessionMutation.mutate('1234')
    }

    const billingEnabled = details?.billingEnabled ?? false;

    return (
        <Box sx={{backgroundColor: Tokens.backgroundColor, height: `100vh`}}>
            <AppBar elevation={10} sx={{position: 'fixed'}}>
                <Toolbar sx={{display: 'flex', justifyContent: 'space-between'}}>
                    <Button sx={{color: '#000'}} onClick={() => navigate('/')}>
                        <ArrowBack/>
                        <Typography>Back to Dashboard</Typography>
                    </Button>
                    <Tooltip title="Account">
                        <Button aria-label="Account" name="Account" id='menu-button'
                                aria-controls={open ? 'account-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={open ? 'true' : undefined}
                                sx={{minHeight: '64px', borderRadius: '0', color: '#000'}}
                                onClick={handleClick}>
                            <AccountBox sx={{transform: "scale(1.8)"}}/>
                            <Typography component='div' sx={{display: 'inline'}} ml={2}>{details?.username}</Typography>
                        </Button>
                    </Tooltip>
                    <Menu id='account-menu'
                          anchorEl={anchorEl}
                          open={open}
                          onClose={handleClose}
                          MenuListProps={{
                              'aria-labelledby': 'menu-button',
                              sx: {borderRadius: '0px'}
                          }}
                          PaperProps={{
                              sx: {borderRadius: '0px'}
                          }}
                    >
                        <MenuItem onClick={() => handleClose('account')}>Account</MenuItem>
                        <MenuItem onClick={() => handleClose('logout')}>Logout</MenuItem>
                    </Menu>
                </Toolbar>
            </AppBar>
            <Box sx={{height: '100%'}}>
                <Container sx={{height: '100%', display: 'flex'}}>
                    <Paper sx={{padding: 8, margin: 'auto', width: '50%', borderRadius: 2}} elevation={5}>
                        <Typography variant='h4'>Account</Typography>
                        <Box sx={{display: 'flex'}} p={2}>
                            <Avatar>{details?.username[0]}</Avatar>
                            <Box ml={1} sx={{display: 'flex'}}>
                                <Typography variant='caption' m={'auto'}
                                            fontSize={'large'}>{details?.username}</Typography>
                                <Divider sx={{marginLeft: 2}} orientation='vertical' flexItem/>
                                <Typography m={'auto'} fontSize={'large'}
                                            sx={{marginLeft: 2}}>Joined: {new Date(details?.createdAt ?? new Date()).toLocaleDateString()}</Typography>
                            </Box>
                        </Box>
                        <Divider/>
                        <Typography mt={2} mb={1} sx={{fontWeight: 'bold'}}>Your Details</Typography>
                        <Stack spacing={2}>
                            {/*<Box sx={{display: 'flex'}}>*/}
                            {/*    <TextField defaultValue={displayName}*/}
                            {/*               label={'Display Name'}*/}
                            {/*               disabled={!editingDisplayName}*/}
                            {/*               sx={{width: '300px'}}/>*/}
                            {/*    <Box sx={{my: 'auto'}}>*/}
                            {/*        <IconButton*/}
                            {/*            onClick={() => editingDisplayName ? setEditing('') : setEditing('displayName')}>*/}
                            {/*            <Edit/>*/}
                            {/*        </IconButton>*/}
                            {/*    </Box>*/}
                            {/*</Box>*/}
                            <Box sx={{display: 'flex'}}>
                                <TextField
                                    defaultValue={displayEmail}
                                    disabled={!editingEmail}
                                    sx={{width: '300px'}}
                                    label='Email'
                                />
                                {/*<Box sx={{my: 'auto'}}>*/}
                                {/*    <IconButton onClick={() => editingEmail ? setEditing('') : setEditing('email')}>*/}
                                {/*        <Edit/>*/}
                                {/*    </IconButton>*/}
                                {/*</Box>*/}
                            </Box>
                            <Box>
                                <Button sx={{borderRadius: 0}} variant="contained" onClick={() => {
                                    setChangePasswordDialogOpen(true)
                                }}>
                                    Change Password
                                </Button>
                                <SimpleDialog
                                    title='Change Password?'
                                    description='Are you sure you want to reset your password? A password reset link will be sent to your email address'
                                    confirmText='Email Reset Password Link'
                                    open={changePasswordDialogOpen}
                                    onCancel={() => {
                                        setChangePasswordDialogOpen(false)
                                    }}
                                    onConfirm={() => resetPasswordMutation.mutate()}
                                    loading={resetPasswordMutation.isLoading}
                                />
                            </Box>
                        </Stack>

                        {billingEnabled &&
                            <>
                                <Divider sx={{mt: 2}}/>
                                <Typography mt={2} mb={1} sx={{fontWeight: 'bold'}}>Billing</Typography>
                                <Typography gutterBottom>PrivateUPM uses Stripe for automated billing, so you'll be
                                    redirected to Stripe to manage your billing information.</Typography>
                                <Button sx={{borderRadius: 0}}
                                        onClick={openCustomerPortal}
                                        variant='contained'
                                        disabled={!billingEnabled || createPortalSessionMutation.isLoading}>Open Billing
                                    Portal</Button>
                            </>
                        }
                        <Divider sx={{mt: 2}}/>
                        <Typography mt={2} mb={1} sx={{fontWeight: 'bold'}}>Security</Typography>
                        <Typography gutterBottom>Permanently close your PrivateUPM account</Typography>
                        <Button sx={{borderRadius: 0}} variant="contained" color='error'>Close Account</Button>
                    </Paper>
                </Container>
            </Box>
        </Box>
    )
}