import React from 'react';
import {Formik, Field, Form} from 'formik';
import {TextField} from 'formik-mui'
import * as Yup from 'yup';

import {useMutation, useQueryClient} from "react-query";
import {postInviteUsers} from "../api";
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle, MenuItem,
} from "@mui/material";

interface IInviteModalProps {
    show: boolean;
    onCloseClick: () => void;
    cancelText?: string;
    confirmText?: string;
    registry: string;
}

const InviteSchema = Yup.object().shape({
    email: Yup.string().email('Invalid email').required('Required'),
    role: Yup.string().required('Required').oneOf(['member', 'manager'])
});

export const InviteModal = ({
                                show,
                                onCloseClick,
                                cancelText,
                                confirmText,
                                registry
                            }: IInviteModalProps) => {
    const queryClient = useQueryClient();

    const useInviteUsersMutation = useMutation({
        mutationFn: ({email, role}: { email: string, role: string }) => postInviteUsers(registry, [{email, role}]),
        onSuccess: async () => {
            await queryClient.invalidateQueries({
                queryKey: ['invitedMembers:' + registry]
            })

            onCloseClick();
        }
    })

    const toggle = () => {
        onCloseClick();
    }

    const roleText = (role: string) => {
        switch (role) {
            case 'member':
                return 'Members can read and write packages to the registry.'
            case 'manager':
                return 'Managers can read and write packages and manage packages in the registry.'
            default:
                return ''
        }
    }

    return (
        <Dialog open={show}>
            <Formik
                initialValues={{email: '', role: 'member'}}
                validationSchema={InviteSchema}
                validateOnChange={true}
                onSubmit={({email, role}) =>
                    useInviteUsersMutation.mutate({email, role})
                }>{
                ({
                     submitForm,
                     isValid,
                     dirty,
                     values: {role}
                 }) => (<Form>
                        <DialogTitle>Invite a new team member</DialogTitle>
                        <DialogContent dividers>
                            <DialogContentText mb={2}>Invite a new user to join you registry. This will grant them
                                access to
                                packages.</DialogContentText>
                            <Field
                                component={TextField}
                                type="email"
                                name="email"
                                label="Email"
                                placeholder="Enter email address"
                                fullWidth
                            />
                            <Box mt={2} display='flex' sx={{
                                alignItems: 'center',
                                flexDirection: 'row',
                                justifyContent: 'start'
                            }}>
                                <Field
                                    component={TextField}
                                    name="role"
                                    label="Role"
                                    select
                                    sx={{width: '200px', minWidth: '200px'}}
                                >
                                    <MenuItem value='member'>Member</MenuItem>
                                    <MenuItem value='manager'>Manager</MenuItem>
                                </Field>
                                <Box pl={2}>
                                    <DialogContentText>{roleText(role)}</DialogContentText>
                                </Box>
                            </Box>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={toggle} color='error'
                                    sx={{borderRadius: 0}}>Cancel</Button>
                            <Button autoFocus={true} disabled={!dirty || !isValid || useInviteUsersMutation.isLoading}
                                    onClick={submitForm}
                                    sx={{borderRadius: 0}}>Invite</Button>
                        </DialogActions>
                    </Form>
                )
            }
            </Formik>
        </Dialog>
    )
}
