import React, {useRef, useState} from 'react';
import {useParams} from "react-router-dom";

import {useQuery} from "react-query";
import {
    Box,
    CircularProgress,
    Fab,
    Paper,
    Snackbar,
    Tab,
    Tabs,
    Typography
} from "@mui/material";
import {
    Delete, PersonAdd
} from "@mui/icons-material"
import {DataGrid, GridActionsCellItem} from "@mui/x-data-grid";

import {getInvitedRegistryMembers, getRegistryMembers } from "../../api";
import {InviteModal} from "../../components/InviteModal";
import {DeleteRegistryUserModal} from "../../components/DeleteRegistryUserModal";
import {formatDateTime} from "../../libs/datetime";
import {DeleteInvitedUserModal} from "../../components/DeleteInvitedUserModal";

type TeamMember = {
    userId: string;
    displayName: string;
    email: string;
    memberSince: string;
    role: string;
    registryId: string;
    registryName: string;
}

export const Team = () => {
    const [tabPage, setTabPage] = useState('active-members')
    const [showDeleteUserModal, setShowDeleteUserModal] = useState(false)
    const [showDeleteInvitedUserModal, setShowDeleteInvitedUserModal] = useState(false)
    const [showInviteModal, setShowInviteModal] = useState(false)
    const [userDeletedSnackbar, setUserDeletedSnackbar] = useState(false)


    const deleteByEmail = useRef('');
    const deleteByUsername = useRef('');

    const params = useParams();
    const selectedRegistry = params['registry_name'] ?? '';

    const getRegistryMembersQuery = useQuery('members:' + selectedRegistry, getRegistryMembers(selectedRegistry))
    const getInvitedMembersQuery = useQuery('invitedMembers:' + selectedRegistry, getInvitedRegistryMembers(selectedRegistry))

    if (getRegistryMembersQuery.isLoading || getInvitedMembersQuery.isLoading) {
        return <CircularProgress/>
    }

    if (getRegistryMembersQuery.isError || !getRegistryMembersQuery.isSuccess || getInvitedMembersQuery.isError || !getInvitedMembersQuery.isSuccess) {
        return <div>error</div>
    }

    const {members} = getRegistryMembersQuery.data;
    const {invitedMembers} = getInvitedMembersQuery.data;

    const ActiveMembersTable = () =>
        <Paper>
            <DataGrid
                showColumnVerticalBorder={true}
                showCellVerticalBorder={true}
                rows={members}
                getRowId={(row) => row.userId}
                columns={[
                    {
                        field: 'email',
                        headerName: 'Username',
                        flex: 1,
                    },
                    {
                        field: 'role',
                        headerName: 'Role',
                        flex: 1,
                    },
                    {
                        field: 'memberSince',
                        headerName: 'Member Since',
                        valueGetter: (params: any) => formatDateTime(params.row.memberSince),
                        flex: 1,
                    },
                    {
                        field: 'actions',
                        type: 'actions',
                        headerName: 'Actions',
                        width: 80,
                        getActions: (params) => [
                            <GridActionsCellItem
                                color='error'
                                icon={<Delete/>}
                                label="Delete"
                                disabled={params.row.role === 'owner'}
                                onClick={() => {
                                }}
                                aria-label="Delete Team Member"
                                title='Delete Team Member'
                            />
                        ]
                    }
                ]}
                initialState={{
                    pagination: {
                        paginationModel: {
                            page: 0,
                            pageSize: 5,
                        }
                    }
                }}
                sx={{
                    '& .MuiDataGrid-row': {
                        cursor: 'pointer'
                    },
                    '& .MuiDataGrid-columnHeaderTitle': {
                        fontWeight: 'bold',
                    }
                }}
                pageSizeOptions={[5, 10, 20]}
                checkboxSelection={false}
                rowSelection={false}
            />
        </Paper>

    const InvitedMembersTable = () => <Paper>
        <DataGrid
            showColumnVerticalBorder={true}
            showCellVerticalBorder={true}
            rows={invitedMembers}
            columns={[
                {
                    field: 'email',
                    headerName: 'Username or Email',
                    flex: 1
                },
                {
                    field: 'role',
                    headerName: 'Role',
                    flex: 1
                },
                {
                    field: 'actions',
                    type: 'actions',
                    headerName: 'Actions',
                    width: 80,
                    getActions: (params) => [
                        <GridActionsCellItem
                            color='error'
                            icon={<Delete/>}
                            label="Delete"
                            onClick={() => {
                                deleteByEmail.current = params.row.email;

                                setShowDeleteInvitedUserModal(true)
                            }}
                        />]
                }
            ]}
            getRowId={(row) => row.email}
            sx={{
                '& .MuiDataGrid-row': {
                    cursor: 'pointer'
                },
                '& .MuiDataGrid-columnHeaderTitle': {
                    fontWeight: 'bold',
                }
            }}
            initialState={{
                pagination: {
                    paginationModel: {
                        page: 0,
                        pageSize: 5,
                    }
                }
            }}
            pageSizeOptions={[5, 10, 20]}
            checkboxSelection={false}
            rowSelection={false}
        />
    </Paper>

    return (
        <Paper sx={{padding: 3}}>
            <Box display='flex'>
                <Typography variant='h4' gutterBottom>Team Members</Typography>
                <Box sx={{display: 'flex', justifyContent: 'flex-end', flex: 'auto'}}>
                    <Fab variant="extended" color='primary' size='small'
                         onClick={() => setShowInviteModal(true)}>
                        <PersonAdd sx={{mr: 1}}/>
                        Invite New Member
                    </Fab>
                </Box>
            </Box>
            <DeleteRegistryUserModal
                open={showDeleteUserModal}
                registry={selectedRegistry}
                email={deleteByEmail.current}
                username={deleteByUsername.current}
                onCloseClick={() => {
                    setShowDeleteUserModal(false)
                    setUserDeletedSnackbar(true)
                }}/>
            <DeleteInvitedUserModal
                open={showDeleteInvitedUserModal}
                registry={selectedRegistry}
                email={deleteByEmail.current}
                username={deleteByUsername.current}
                onCloseClick={() => {
                    setShowDeleteInvitedUserModal(false)
                    setUserDeletedSnackbar(true)
                }}/>
            <InviteModal
                show={showInviteModal}
                onCloseClick={() => setShowInviteModal(false)}
                registry={selectedRegistry}/>
            <Typography mb={2}>Manage the team members that have access to this registry</Typography>
            <Tabs value={tabPage} sx={{marginTop: 2}}>
                <Tab label={`Active Members (${members.length})`} value="active-members"
                     onClick={() => setTabPage('active-members')}/>
                <Tab label={`Pending Members (${invitedMembers.length})`} value="invited-members"
                     onClick={() => setTabPage('invited-members')}/>
            </Tabs>
            <Snackbar
                open={userDeletedSnackbar}
                autoHideDuration={5000}
                onClose={(event: React.SyntheticEvent | Event, reason?: string) => {
                    if (reason === 'clickaway') {
                        return;
                    }

                    setUserDeletedSnackbar(false)
                }}
                message="User removed from registry."
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            />
            {tabPage === 'active-members' && <ActiveMembersTable/>}
            {tabPage === 'invited-members' && (invitedMembers.length > 0 ? <InvitedMembersTable/> : <Typography>There are no pending invites for this registry.</Typography>)}
        </Paper>
    );
}