import React from 'react';
import {Paper, Typography} from "@mui/material";


export const Settings = () => {

    return (
        <Paper sx={{padding: 3}}>
            <Typography variant='h4'>Settings</Typography>
            <Typography>Delete Registry</Typography>
            <Typography>Change Registry Plan</Typography>
        </Paper>
    )
}