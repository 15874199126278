import React from 'react';
import {NoRegistries} from "./Registry/NoRegistries";
import {Link as RouterLink, Outlet, useLocation, useParams} from "react-router-dom";
import {useMutation, useQuery, useQueryClient} from "react-query";
import {getDashboard, postRespondRegistryInvite} from "../api";
import {
    Box, Button,
    CircularProgress,
    Container, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, LinearProgress, Paper,
    Tab,
    Tabs, Typography,
} from "@mui/material";
import {Simulate} from "react-dom/test-utils";
import abort = Simulate.abort;


const hasPermission = (role: string, permission: string) => {
    switch (role) {
        case 'member':
            return permission === 'member'
        case 'manager':
            return permission === 'member' || permission === 'manager'
        case 'owner':
            return true
        default:
            return false
    }
}


type RegistryInvitationProps = {
    registryName: string
}
const RegistryInvitation = ({registryName}: RegistryInvitationProps) => {
    const queryClient = useQueryClient()
    const respondInviteMutation = useMutation({
        mutationFn: async (response: string) => {
            return postRespondRegistryInvite(registryName, response)
        },
        onSuccess: () => queryClient.invalidateQueries('dashboard')
    })

    const loading = respondInviteMutation.isLoading

    return (<Box sx={{margin: 'auto'}}>
        <Paper sx={{margin: 2, borderRadius: 2}} elevation={5}>
            <Typography variant='h5' p={2} textAlign='center'>Registry Invitation</Typography>
            <Divider/>
            <Typography variant='subtitle1' p={2}>You have been invited to join the
                registry {registryName}</Typography>
            <Divider/>
            {loading && <LinearProgress/>}
            <Box sx={{display: 'flex', justifyContent: 'space-evenly'}}>
                <Button disabled={loading} size='large' onClick={() => respondInviteMutation.mutate('accept')}>Accept
                    Invitation</Button>
                <Button disabled={loading} size='large' color='error'
                        onClick={() => respondInviteMutation.mutate('decline')}>Decline
                    Invitation</Button>
            </Box>
        </Paper>
    </Box>)
}

export const Registry = () => {
    const params = useParams();
    const registryName = params['registry_name'] ?? '';
    const getDashboardQuery = useQuery('dashboard', getDashboard)
    const location = useLocation();

    if (getDashboardQuery.isLoading) {
        return <CircularProgress/>
    }

    if (getDashboardQuery.isError || !getDashboardQuery.isSuccess) {
        return <div>error</div>
    }

    if (getDashboardQuery.data.registries.length == 0) {
        return <NoRegistries/>
    }

    const registryData = (getDashboardQuery.data.registries.find((x) => x.name === registryName) ?? getDashboardQuery.data.registries[0]);

    if (registryData.invitation) {
        return <RegistryInvitation registryName={registryData.name}/>
    }

    const tabs = {
        "packages": {
            name: "Packages",
            permission: "member",
        },
        "team-members": {
            name: "Team Members",
            permission: "manager",
        },
        "overview": {
            name: "Usage",
            permission: "owner",
        },
        // "api-keys": {
        //     name: "Api Keys",
        //     permission: "manager",
        // },

        // "billing": {
        //     name: "Billing",
        //     permission: "owner",
        // },
        "settings": {
            name: "Settings",
            permission: "owner",
        },
    }
    const tabsToDisplay = Object.entries(tabs).filter(([_, value]) => hasPermission(registryData.role, value.permission))

    const getRedirect = (pane: string) => {
        switch (pane) {
            case 'overview':
                return `/registry/${registryData.name}/overview`
            case 'packages':
                return `/registry/${registryData.name}`
            case 'api-keys':
                return `/registry/${registryData.name}/api-keys`
            case 'team-members':
                return `/registry/${registryData.name}/team-members`
            case 'settings':
                return `/registry/${registryData.name}/settings`
        }
        return ''
    }

    const getTabValue = () => {
        const page = location.pathname.split('/').pop()
        switch (page) {
            case registryData.name:
                return 0
            case 'team-members':
                return 1
            case 'overview':
                return 2
            // case 'api-keys':
            //     return 1
            // case 'billing':
            //     return 4
            case 'settings':
                return 3
        }

        return 0
    }

    return (
        <Box sx={{width: '100%'}} p={3}>
            <Container>
                <Box>
                    <Tabs value={getTabValue()}>
                        {tabsToDisplay.map(([key, value]) => {
                            return <Tab draggable={false} key={key} component={RouterLink} to={getRedirect(key)} label={value.name}/>
                        })}
                    </Tabs>
                </Box>
                <Box>
                    <Outlet/>
                </Box>
            </Container>
        </Box>)
}