import React, {useMemo, useState} from 'react'
import {Link as RouterLink, useLocation, useSearchParams} from "react-router-dom";
import {Formik, Form, Field} from 'formik'
import {TextField} from 'formik-mui'
import * as Yup from 'yup';
import {
    Box,
    InputAdornment,
    Stack,
    Typography,
    Button,
    FormGroup,
    Link, LinearProgress
} from "@mui/material";
import {Email, Lock, MarkEmailRead, Person} from "@mui/icons-material";
import {useMutation} from "react-query";
import Turnstile from "react-turnstile";

import {ValidateEmail} from "../libs/emailValidation";
import {postSignupRequest, TurnstileSiteKey} from "../api";

const isAlphaNumericRE = new RegExp(/^[a-z0-9]+$/i)

const SignupSchema = Yup.object().shape({
    email: Yup.string()
        .trim()
        .max(200)
        .email('A valid email must be provided')
        .required('Email is required'),
    username: Yup.string()
        .min(4, 'Username must be 4 characters or more')
        .max(16, 'Username cannot be longer than 16 characters')
        .required('Username is required')
        .test('username', 'Username must be only alphanumeric characters', value => {
            return isAlphaNumericRE.test(value ?? '')
        }),
    password: Yup.string()
        .required('Password is required')
        .min(8, 'Password must be 8 or more characters')
        .max(256, 'Password cannot be longer than 256 characters')
        .test('password', 'Password must contain at least one uppercase letter, one lowercase letter, and one number', value => {
            return /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).+$/.test(value ?? '')
        }),
    confirmPassword: Yup.string().required('Confirm Password is required').oneOf([Yup.ref('password')], 'Passwords must match')
})


export const SignUp: React.FC = () => {
    const location = useLocation();
    const [searchParams] = useSearchParams();

    const invitationLink = useMemo(() => location.pathname = '/signup-invited', [location])
    const initialEmail = useMemo(() => decodeURI(searchParams.get('email') ?? ''), [searchParams]);
    const initialEmailValid = useMemo(() => ValidateEmail(initialEmail), [initialEmail]);

    const [turnstileToken, setTurnstileToken] = useState('')
    const [linkSentSuccessfully, setLinkSentSuccessfully] = useState(false)
    const [response, setResponse] = useState('')

    const signUpMutation = useMutation({
        mutationFn:  async ({
                               email,
                               password,
                               username}
                           : {
            email: string,
            username: string,
            password: string
        }) => {
            return postSignupRequest(username, password, email, turnstileToken)
        },
        onSuccess: result => {
            switch (result.status) {
                case 'failed':
                    setResponse(result.message ?? 'An unknown error occurred')
                    break;
                case 'success':
                    setLinkSentSuccessfully(true);
                    break;
                default:
                    setResponse('An unknown error occurred')
            }
        },
    })

    if (linkSentSuccessfully) {
        return (<Box>
            <MarkEmailRead fontSize='large' color='success'/>
            <Typography fontSize='large' fontWeight='bold' gutterBottom>Sign Up Verification Email Sent!</Typography>
            <Typography fontSize='normal' mt={2}>Please click the link in the verification email to activate your PrivateUPM account! 🚀</Typography>
        </Box>)
    }

    const editEmailDisabled = !!invitationLink && initialEmailValid

    return (
        <Formik
            initialValues={{
                email: initialEmail,
                username: '',
                password: '',
                confirmPassword: ''
            }}
            validationSchema={SignupSchema}
            onSubmit={({email, username, password}, {setSubmitting}) =>
            {
                return signUpMutation.mutate({
                    email: email.trim(),
                    username: username.trim(),
                    password
                })
            }

            }>
            {({
                  submitForm,
                  isSubmitting,
                  isValid,
                  dirty
              }) => (
                <Form>
                    <FormGroup>
                        <Stack spacing={3} sx={{justifyContent: 'center'}}>
                            <Typography variant='h4'>Register Account</Typography>
                            <Typography variant='subtitle1' gutterBottom>Create a new PrivateUPM account</Typography>
                            <Field
                                component={TextField}
                                label='Email'
                                name='email'
                                type='email'
                                placeholder={editEmailDisabled ? initialEmail : 'Enter Email Address'}
                                InputProps={{
                                    startAdornment: <InputAdornment position='start'><Email/></InputAdornment>
                                }}
                                helperText={editEmailDisabled ? "Your email address has been prefilled." : null}
                            />
                            <Field
                                component={TextField}
                                label='Username'
                                name='username'
                                placeholder='Enter Username'
                                InputProps={{
                                    startAdornment: <InputAdornment position='start'><Person/></InputAdornment>
                                }}
                            />

                            <Field
                                component={TextField}
                                label='Password'
                                type='password'
                                name='password'
                                placeholder='Enter Password'
                                InputProps={{
                                    startAdornment: <InputAdornment position='start'><Lock/></InputAdornment>
                                }}
                            />

                            <Field
                                component={TextField}
                                label='Confirm Password'
                                type='password'
                                name='confirmPassword'
                                placeholder='Confirm Password'
                                InputProps={{
                                    startAdornment: <InputAdornment position='start'><Lock/></InputAdornment>
                                }}
                            />

                            {response && <Typography variant='body1' color='error'>{response}</Typography>}
                            {isSubmitting && <LinearProgress/>}
                            <Box sx={{
                                margin: 'auto',
                            }}>
                                <Typography variant='caption'>By registering you agree to PrivateUPM's <Link
                                    component={RouterLink} to='/terms-of-use'>Terms
                                    of Use.</Link></Typography>
                                <Button variant='contained' sx={{
                                    borderRadius: '0px',
                                    width: '50%',
                                    padding: '10px',
                                }}
                                        disabled={!dirty || !isValid || !turnstileToken || isSubmitting}
                                        onClick={submitForm}
                                >Sign Up</Button>
                            </Box>
                            <Turnstile sitekey={TurnstileSiteKey}
                                       action={'sign-up'}
                                       onVerify={token => {
                                           setTurnstileToken(token)
                                       }}/>

                            <Typography variant='button'><Link component={RouterLink} to={'/forgot-password'}>Forgot
                                Password?</Link></Typography>
                            <Typography>Already have an account? <Link component={RouterLink} to='/signin'>Sign
                                In</Link></Typography>
                        </Stack>
                    </FormGroup>
                </Form>
            )}
        </Formik>
    )
}