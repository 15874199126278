import React from 'react';
import {Outlet, useNavigate} from "react-router-dom";
import {Box, Paper, Container} from "@mui/material";
import {Tokens} from "../tokens/tokens";

export const NonAuthLayout = () => {
    return         <Box sx={{
        textAlign: 'center',
        backgroundColor: 'rgb(239, 242, 247)',
        minHeight: '100vh',
        height: '100%',
        display: 'flex',
    }}>

        <Container sx={{
            display: 'flex',
            justifyContent: 'center',
            marginY: 'auto',
        }}>
            <Box sx={{
                margin: 'auto',
            }}>
                <Box sx={{
                    display: 'inline-flex',
                    width: '25%',
                    padding: '20px',
                }}
                >
                    <a href='/' draggable={false}><img src={Tokens.LogoDark} alt='PrivateUPM Logo' width='100%' draggable={false}/></a>
                </Box>
                <Paper elevation={3} sx={{
                    padding: '60px',
                    margin: 'auto',
                    width: '340px',
                }}><Outlet/></Paper>
            </Box>
        </Container>
    </Box>
}

