import {
    CreateCheckoutRequest,
    CreateCheckoutResponse,
    CreatePortalSessionRequest,
    CreatePortalSessionResponse,
    EmbeddedCheckoutRequest,
    EmbeddedCheckoutResponse,
    GetAccountDetailsResponse,
    GetAPIKeysResponse,
    GetDashboardResponse,
    GetInvitedMembersResponse,
    GetPackageDetailsResponse,
    GetPackagesResponse,
    GetRegistryDetailsResponse,
    GetRegistryMembersResponse,
    GetRegistryUsageResponse,
    InviteUser,
    PostConfirmPasswordResetRequest,
    PostConfirmPasswordResetResponse,
    PostConfirmUserRequest,
    PostConfirmUserResponse,
    PostCreateAPIKeyRequest,
    PostCreateAPIKeyResponse,
    PostCreateRegistryRequest,
    PostCreateRegistryResponse,
    PostForgotPasswordResetRequest,
    PostForgotPasswordResetResponse,
    PostInviteUsersRequest, PostInviteUsersResponse, PostRemoveUserInviteRequest, PostRemoveUserInviteResponse,
    PostResetPasswordRequest,
    PostResetPasswordResponse,
    PostSignUpRequest,
    PostSignUpResponse, RespondRegistryInviteRequest, RespondRegistryInviteResponse,
    SubscribeRegistryRequest,
    SubscribeRegistryResponse
} from "./types";

export const API_URL = process.env['REACT_APP_API_URL'] || "https://api.privateupm.com"
const environment = process.env['REACT_APP_ENVIRONMENT']

export const TurnstileSiteKey = process.env['REACT_APP_TURNSTILE_SITE_KEY'] || ""
export const getAuthToken = () => environment === "offline" ? "fakeToken" : localStorage.getItem('accessToken') || ""

const baseURL = API_URL;

function fetchAuthicatedJSON<T>(path: string): () => Promise<T> {
    return async (): Promise<T> => {
        const response = await fetch(`${baseURL}${path}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getAuthToken()
            }
        })

        if (!response.ok) {
            throw new Error(response.statusText);
        }

        return await response.json()
    }
}

function postAuthenticatedJson<T, K>(path: string, data: T): () => Promise<K> {
    return async (): Promise<K> => {
        if (!path || path.length === 0) return {} as K;
        const response = await fetch(`${baseURL}${path}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getAuthToken()
            },
            body: JSON.stringify(data)
        })
        if (response.status.toString()[0] !== '2') {
            throw new Error(response.statusText)
        }

        return await response.json()
    }
}

function postJson<T, K>(path: string, data: T): () => Promise<K> {
    return async (): Promise<K> => {
        if (!path || path.length === 0) return {} as K;
        const response = await fetch(`${baseURL}${path}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data)
        })
        if (response.status.toString()[0] !== '2') {
            throw new Error(response.statusText)
        }
        return await response.json()
    }
}

function deleteAuthenticated(path: string): () => Promise<void> {
    return async (): Promise<void> => {
        const response = await fetch(`${baseURL}${path}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getAuthToken()
            }
        })

        if (response.status.toString()[0] !== '2') {
            throw new Error(response.statusText)
        }
    }
}

function deleteAuthenticatedJson<T>(path: string, data: T): () => Promise<void> {
    if (!path || path.length === 0) return async (): Promise<void> => {
    };
    return async (): Promise<void> => {
        const response = await fetch(`${baseURL}${path}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getAuthToken()
            },
            body: JSON.stringify(data)
        })

        if (response.status.toString()[0] !== '2') {
            throw new Error(response.statusText)
        }

        return await response.json()
    }
}

export const getDashboard = fetchAuthicatedJSON<GetDashboardResponse>('/dashboard');

export const getRegistryDetails = (registry?: string) => fetchAuthicatedJSON<GetRegistryDetailsResponse>(`/registry/${registry}`);

export const getAPIKeys = (registry: string) => fetchAuthicatedJSON<GetAPIKeysResponse>(`/api-keys/${registry}`);

export const getPackages = (registry: string) => fetchAuthicatedJSON<GetPackagesResponse>(`/packages/${registry}`)

export const getRegistryMembers = (registry: string) => fetchAuthicatedJSON<GetRegistryMembersResponse>(`/members/${registry}`)

export const getInvitedRegistryMembers = (registry: string) => fetchAuthicatedJSON<GetInvitedMembersResponse>(`/invited-members/${registry}`)

export const postCreateRegistry = async (name: string, plan: string) => postAuthenticatedJson<PostCreateRegistryRequest, PostCreateRegistryResponse>('/registry/create', {
    name,
    plan
})()

export const postInviteUsers = async (registry: string, invitedUsers: Array<InviteUser>) => postAuthenticatedJson<PostInviteUsersRequest, PostInviteUsersResponse>(`/invite-members`, {
    invitedUsers,
    registry
})()

export const postRemoveUserInvite = async (registry: string, emails: Array<string>) => postAuthenticatedJson<PostRemoveUserInviteRequest, PostRemoveUserInviteResponse>(`/remove-registry-invite`, {emails, registry})()

export const postCreateAPIKey = async (name: string, package_registry: string, expires_at: string) => postAuthenticatedJson<PostCreateAPIKeyRequest, PostCreateAPIKeyResponse>(`/api-key/create`, {
    name,
    package_registry,
    expires_at
})()

export const postCreateCheckoutSession = (lookupKey: string) => postAuthenticatedJson<CreateCheckoutRequest, CreateCheckoutResponse>(`/payment/create-checkout-session`, {lookupKey})()

export const postCreatePortalSession = (idempotency_key: string) => postAuthenticatedJson<CreatePortalSessionRequest, CreatePortalSessionResponse>(`/payment/create-portal-session`, {idempotency_key})()

export const postSubscribeRegistry = (registry_name: string, plan: string, idempotency_key: string) => postAuthenticatedJson<SubscribeRegistryRequest, SubscribeRegistryResponse>(`/payment/subscribe-registry`, {
    registry_name,
    plan,
    idempotency_key
})()
export const postEmbeddedCheckout = (registry_name: string, plan: string, idempotency_key: string) => postAuthenticatedJson<EmbeddedCheckoutRequest, EmbeddedCheckoutResponse>(`/payment/embedded-checkout`, {
    registry_name,
    plan,
    idempotency_key
})()

export const deleteAPIKey = async (apiKeyId: string) => deleteAuthenticatedJson(`/api-key/delete`, {id: apiKeyId})()

export const getPackageDetails = (registry: string, packageName: string) => fetchAuthicatedJSON<GetPackageDetailsResponse>(`/package-details/${registry}/${packageName}`)

export const getRegistryUsage = (registry: string) => fetchAuthicatedJSON<GetRegistryUsageResponse>(`/registry/usage/${registry}`)

export const getAccountDetails = () => fetchAuthicatedJSON<GetAccountDetailsResponse>('/account/details')

export const postSignupRequest = (userName: string, password: string, email: string, token :string) => postJson<PostSignUpRequest, PostSignUpResponse>(`/user/signup`, { userName, password, email, token})()

export const postConfirmUserRequest = (req: PostConfirmUserRequest) => postJson<PostConfirmUserRequest, PostConfirmUserResponse>('/user/confirm', req)()

export const deleteRegistryUsers = (registry: string, user_emails: Array<string>) => deleteAuthenticatedJson(`/registry-delete-users`, {registry, user_emails})()


// Reset the user password via token (Authed)
export const postResetUserPassword = () => postAuthenticatedJson<PostResetPasswordRequest, PostResetPasswordResponse>(`/user/password-reset`, {})()

// Reset the user password via email (Unauthed)
export const postForgotUserPasswordReset = (token: string, email: string) => postJson<PostForgotPasswordResetRequest, PostForgotPasswordResetResponse>(`/user/forgot-password-reset`, { token, email})()

export const postConfirmUserPasswordReset = (token: string, username: string, code: string, new_password: string) => postJson<PostConfirmPasswordResetRequest, PostConfirmPasswordResetResponse>(`/user/confirm-password-reset`, {token, username, new_password, code})()

export const postRespondRegistryInvite = (registry_name: string, response: string) => postAuthenticatedJson<RespondRegistryInviteRequest, RespondRegistryInviteResponse>(`/registry-invite-respond`, {registry_name, response})()