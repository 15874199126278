import React from "react";
import {useMutation, useQueryClient} from "react-query";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    LinearProgress
} from "@mui/material";

import { postRemoveUserInvite} from "../api";

type DeleteInvitedUserModalProps = {
    open: boolean;
    username?: string;
    email?: string;
    onCloseClick: () => void;
    registry: string;
}

export const DeleteInvitedUserModal = ({
                                           open,
                                           username,
                                           email,
                                           registry,
                                           onCloseClick
                                       }: DeleteInvitedUserModalProps) => {

    const queryClient = useQueryClient();

    const removeRegistryInviteMutation = useMutation({
        mutationFn: ({email, username}: {
            email?: string,
            username?: string
        }) => {
            if (!email) {
                return Promise.reject('Invalid member');
            }
            return postRemoveUserInvite(registry, [email])
        },
        onSuccess: async () => {
            await queryClient.invalidateQueries({
                queryKey: ['invitedMembers:' + registry]
            })
            onCloseClick();
        }

    });
    return (
        <Dialog open={open}>
            <DialogTitle>Remove user invitation?</DialogTitle>
            <DialogContent dividers>
                <DialogContentText>{`Are you sure you want to remove ${username ? username : email}?`}</DialogContentText>
            </DialogContent>
            {removeRegistryInviteMutation.isLoading && <LinearProgress/>}
            <DialogActions>
                <Button sx={{borderRadius: 0}} onClick={onCloseClick} variant='outlined'>
                    Cancel
                </Button>
                <Button
                    sx={{borderRadius: 0}}
                    id="delete-record"
                    onClick={() => removeRegistryInviteMutation.mutate({email, username})}
                    color='error'
                    variant='outlined'
                    disabled={removeRegistryInviteMutation.isLoading || (!email && !username)}
                >
                    {'Delete'}
                </Button>
            </DialogActions>
        </Dialog>
    )
}