import React from 'react';
import {useQuery} from "react-query";
import {useNavigate, useParams} from "react-router-dom";
import {
    Box,
    Divider,
    Paper, Skeleton,
    Typography,
} from "@mui/material";
import {
    Delete
} from "@mui/icons-material";

import {getPackages} from "../../api";
import {DataGrid, GridActionsCellItem} from "@mui/x-data-grid";
import {formatDateTime} from "../../libs/datetime";
import {Tokens} from "../../tokens/tokens";

export const Packages = () => {
    const navigate = useNavigate();

    const params = useParams();
    const registry_name = params['registry_name'] ?? ''
    const getPackagesQuery = useQuery('packages:' + registry_name, getPackages(registry_name))

    if (getPackagesQuery.isLoading) {
        return <Skeleton variant="rectangular" animation='pulse'/>
    }

    if (getPackagesQuery.isError || !getPackagesQuery.isSuccess) {
        return <div>error</div>
    }

    const {packages} = getPackagesQuery.data

    if (packages.length <= 0) {
        return (<Paper sx={{padding: 3,}}>
            <Typography variant='h4'gutterBottom>Packages</Typography>
            <Box sx={{textAlign: 'center'}}>
                <Typography variant={'h4'} sx={{ color: Tokens.mutedTextColor}} gutterBottom >No packages found in '{registry_name}'</Typography>
            </Box>
            <Divider/>
            <Typography variant='h5' mt={2}>Upload a package</Typography>
            <ol>
                <li><Typography variant='body1'>Open Unity Editor</Typography></li>
                <li><Typography variant='body1'>Download and Install the PrivateUPM Unity Plugin from the Unity Asset
                    Store</Typography></li>
                <li><Typography variant='body1'>Add the PrivateUPM Unity Plugin to your Unity Project</Typography></li>
                <li><Typography variant='body1'>Open the PrivateUPM Inspector window in Unity:</Typography>
                    <ul>
                        <li><Typography variant='body1'>Window {'>'} PrivateUPM</Typography></li>
                    </ul>
                </li>
                <li><Typography>Login to the registry</Typography></li>
                <li><Typography>Upload your package</Typography></li>
            </ol>
        </Paper>)
    }

    return (
            <Paper sx={{padding: 3}}>
            <Typography variant='h4' gutterBottom>Packages</Typography>
                <DataGrid
                    showColumnVerticalBorder={true}
                    showCellVerticalBorder={true}
                    rows={packages}
                    columns={[
                        {
                            field: 'name',
                            headerName: 'Package',
                            flex: 1,
                        },
                        {
                            field: 'scope',
                            headerName: 'Scope',
                            flex: 1,
                        },
                        {
                            field: 'created',
                            headerName: 'Created',
                            valueGetter: (params: any) => formatDateTime(params.row.created),
                            flex: 1,
                        },
                        {
                            field: 'updated',
                            headerName: 'Updated',
                            valueGetter: (params: any) => formatDateTime(params.row.updated),
                            flex: 1,
                        },
                        {
                            field: 'actions',
                            headerName: 'Actions',
                            type: 'actions',
                            width: 80,
                            getActions: (params) => [
                                <GridActionsCellItem
                                    color='error'
                                    icon={<Delete/>}
                                    label="Delete"
                                    onClick={() => {
                                    }}
                                />
                            ],
                        },
                    ]}
                    getRowId={(row) => row.name}
                    initialState={{
                        pagination: {
                            paginationModel: {
                                page: 0,
                                pageSize: 20,
                            }
                        }
                    }}
                    sx={{
                        '& .MuiDataGrid-row': {
                            cursor: 'pointer'
                        },
                        '& .MuiDataGrid-columnHeaderTitle': {
                            fontWeight: 'bold',
                        }
                    }}
                    pageSizeOptions={[20, 50, 100]}
                    checkboxSelection={false}
                    rowSelection={false}
                    onRowClick={(e: any) => navigate(`/registry/${registry_name}/packages/${e.id}`)}
                />
            </Paper>
    );
}