import React from "react";
import {Box, Button, Paper, Typography} from "@mui/material";

import {useNavigate} from "react-router-dom";
import {Tokens} from "../../tokens/tokens";

export const NoRegistries = () => {
    const navigate = useNavigate();

    return <Box m={'auto'}>
            <Paper sx={{margin: 'auto', padding: 5, textAlign: 'center'}} elevation={10}>
                <Box p={2}>
                    <img src={Tokens.LogoDark} alt='PrivateUPM' style={{width: '200px'}} draggable={false}/>
                </Box>
                <Typography variant='h1' fontSize='x-large' mb={3} gutterBottom>Welcome!</Typography>
                <Typography gutterBottom mb={4}>You're not a member of a registry yet, would you like to create
                    a registry now?</Typography>
                <Button sx={{width: '50%', padding: 2}}
                        color='success'
                        onClick={() => navigate(`/create/registry`)}
                        variant='contained'>Create Registry</Button>
            </Paper>
        </Box>
}