import React from 'react';

import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle, Divider, LinearProgress,
    Modal,
    Paper,
    Typography
} from '@mui/material';
import {useMutation, useQueryClient} from "react-query";
import {deleteRegistryUsers} from "../api";

type DeleteRegistryUserModalProps = {
    open: boolean;
    username?: string;
    email?: string;
    onCloseClick: () => void;
    registry: string;
}

export const DeleteRegistryUserModal = ({
                                            open,
                                            username,
                                            email,
                                            registry,
                                            onCloseClick
                                        }: DeleteRegistryUserModalProps) => {

    const queryClient = useQueryClient();

    const removeRegistryUserMutation = useMutation({
        mutationFn: ({email, username}: {
            email?: string,
            username?: string
        }) => {
            if (!email) {
                return Promise.reject('Invalid member');
            }
            return deleteRegistryUsers(registry, [email])
        },
        onSuccess: async () => {
            await queryClient.invalidateQueries({
                queryKey: ['members:' + registry]
            })
            onCloseClick();
        }

    });

    return (
        <Dialog open={open} onClose={onCloseClick}>
            <DialogTitle>Remove user from registry?</DialogTitle>
            <DialogContent dividers>
                <DialogContentText>{`Are you sure you want to remove ${username ? username : email}}?`}</DialogContentText>
            </DialogContent>
            {removeRegistryUserMutation.isLoading && <LinearProgress/>}
            <DialogActions>
                <Button sx={{borderRadius: 0}} onClick={onCloseClick} variant='outlined'>
                    Cancel
                </Button>
                <Button
                    sx={{borderRadius: 0}}
                    id="delete-record"
                    onClick={() => removeRegistryUserMutation.mutate({email, username})}
                    color='error'
                    variant='outlined'
                    disabled={removeRegistryUserMutation.isLoading || (!email && !username)}
                >Delete</Button>
            </DialogActions>
        </Dialog>
    );
}