import React from "react";
import {useNavigate, useParams} from "react-router-dom";
import {
    Box,
    Button, CircularProgress,
    Container, Divider, Fab, IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow, Tooltip,
    Typography
} from "@mui/material";
import {ArrowBack, ArrowUpward, Delete} from "@mui/icons-material";
import {useQuery} from "react-query";
import {getPackageDetails} from "../../api";
import {HumanFileSize} from "../../libs/filesizes";
import {DataGrid, GridActionsCellItem, GridColDef, GridRowParams} from "@mui/x-data-grid";
import {formatDateTime} from "../../libs/datetime";


export const PackageDetails = () => {
    const params = useParams()
    const navigate = useNavigate();
    let registryName = params['registry_name'] ?? '';
    let packageName = params['package_name'] ?? '';

    const getPackageDetailsQuery = useQuery('packageDetails:' + registryName + ':' + packageName, getPackageDetails(registryName, packageName))

    if (getPackageDetailsQuery.isLoading) {
        return <CircularProgress/>;
    }

    if (getPackageDetailsQuery.isError || !getPackageDetailsQuery.isSuccess) {
        return <div>error</div>;
    }

    const versions = getPackageDetailsQuery.data.versions;

    const cols: GridColDef[] = [{
        field: 'version',
        headerName: 'Version',
        flex: 1
    },
        {
            field: 'content_length',
            headerName: 'Size',
            valueGetter: (params: any) => HumanFileSize(params.row.content_length),
            flex: 1
        },
        {
            field: 'created',
            headerName: 'Created',
            valueGetter: (params: any) => formatDateTime(params.row.created),
            flex: 2
        }]

    if (false) {
        cols.push({
            field: 'actions',
            headerName: 'Actions',
            type: 'actions',
            width: 80,
            getActions: (params: GridRowParams) => [
                <GridActionsCellItem
                    color='error'
                    icon={<Delete/>}
                    label="Delete"
                    onClick={() => {
                    }}
                />
            ],
        })
    }

    return (
        <Paper sx={{padding: 3}}>
            <Box sx={{display: 'flex', alignItems: 'center'}}>
                <Typography variant='h4' mr={2}>Package Versions</Typography>
                <Divider orientation='vertical' flexItem={true}/>
                <Box ml={2}>
                    <Typography variant='body1'>{`${packageName}`}</Typography>
                </Box>
            </Box>
            <Tooltip title={`Back to ${registryName}`}>
                <Button onClick={() => navigate(`/registry/${registryName}`)}>
                    <ArrowUpward/> Back
                </Button>
            </Tooltip>
            <DataGrid
                showColumnVerticalBorder={true}
                showCellVerticalBorder={true}
                rows={versions}
                columns={cols}
                getRowId={(row) => row.version}
                sx={{
                    '& .MuiDataGrid-row': {
                        cursor: 'pointer'
                    },
                    '& .MuiDataGrid-columnHeaderTitle': {
                        fontWeight: 'bold',
                    }
                }}
                pageSizeOptions={[20, 50, 100]}
                checkboxSelection={false}
                rowSelection={false}
            />
        </Paper>
    )
}