import React, {useState} from 'react';
import {
    Box,
    CircularProgress,
    Typography,
    List,
    ListItemText,
    ListItemIcon,
    ListItemButton,
    Divider,
    Drawer,
    Toolbar,
    Collapse, Button, AppBar, Tooltip, Menu, MenuItem, Paper, IconButton, ListItem, Badge
} from '@mui/material';
import {
    AccountBox,
    Add, BadgeRounded, CatchingPokemon,
    CreateNewFolder,
    ExpandLess,
    ExpandMore, FolderOff,
    FolderOffTwoTone,
    FolderSpecial
} from '@mui/icons-material';
import {useQuery, useQueryClient} from "react-query";

import {getDashboard} from "../api";
import {Folder} from "@mui/icons-material";
import {Link} from "@mui/material";
import {Link as RouterLink, Outlet, useLocation, useNavigate, useParams} from "react-router-dom";
import {NoRegistries} from './Registry/NoRegistries';
import logo from "../assets/images/privateupm-logo.png"
import {AuthContext} from "../contexts/authContext";
import {Tokens} from "../tokens/tokens";

const drawerWidth = 240;
const appbarHeight = 64;

export const Dashboard = () => {
    const [showRegistries, setShowRegistries] = useState(true)
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const getDashboardQuery = useQuery('dashboard', getDashboard)
    const navigate = useNavigate();
    const location = useLocation()
    const queryClient = useQueryClient();
    const authContext = React.useContext(AuthContext)

    const accountMenuOpen = Boolean(anchorEl);
    const selectedRegistry = useParams().registry_name

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = (clicked: string) => {
        setAnchorEl(null);
        switch (clicked) {
            case 'account':
                navigate('/account')
                return;
            case 'logout':
                authContext.signOut();
                queryClient.clear();
                navigate('/signout')
                break;
        }
    };

    if (getDashboardQuery.isError || !getDashboardQuery.isSuccess) {
        return <>Error</>
    }

    const handleRegistryClick = (registryName: string) => {
        navigate(`/registry/${registryName}`)
    }

    const registries = getDashboardQuery.data?.registries ?? []
    const username = getDashboardQuery.data?.user.username ?? 'Loading'

    if (registries.length > 0 && location.pathname === '/registry') {
        navigate(`/registry/${registries[0].name}`)
    }

    if (registries.length === 0 && location.pathname === '/') {
        navigate(`/registry`)
    }

    return (
        <Box>
            <Box sx={{width: '100%'}}>
                <AppBar position='fixed' elevation={10}>
                    <Toolbar sx={{display: 'flex', justifyContent: 'space-between'}}>
                        <Box sx={{maxWidth: '180px'}}>
                            <Link component={RouterLink} to="/" sx={{display: 'flex'}}>
                                <img src={Tokens.LogoDark} alt='PrivateUPM Logo' width='100%' draggable={false}/>
                            </Link>
                        </Box>
                        <Box>
                            <Button color='primary' variant='contained' sx={{mr: 3, borderRadius: 0}}
                                    onClick={() => navigate('/create/registry')}>
                                <Add/>
                                Create Registry
                            </Button>
                            <Tooltip title="Account">
                                <Button aria-label="Account" name="Account" id='menu-button'
                                        aria-controls={accountMenuOpen ? 'account-menu' : undefined}
                                        aria-haspopup="true"
                                        aria-expanded={accountMenuOpen ? 'true' : undefined}
                                        sx={{minHeight: '64px', borderRadius: '0', color: '#000'}}
                                        onClick={handleClick}>
                                    <AccountBox sx={{transform: "scale(1.8)"}}/>
                                    <Typography component='div' sx={{display: 'inline'}} ml={2}>{username}</Typography>
                                </Button>
                            </Tooltip>
                            <Menu id='account-menu'
                                  anchorEl={anchorEl}
                                  open={accountMenuOpen}
                                  onClose={handleClose}
                                  MenuListProps={{
                                      'aria-labelledby': 'menu-button',
                                      sx: {borderRadius: '0px'}
                                  }}
                                  PaperProps={{
                                      sx: {borderRadius: '0px'}
                                  }}
                            >
                                <MenuItem onClick={() => handleClose('account')}>Account</MenuItem>
                                <MenuItem onClick={() => handleClose('logout')}>Logout</MenuItem>
                            </Menu>
                        </Box>
                    </Toolbar>
                </AppBar>
            </Box>
            <Drawer
                variant="persistent"
                open={true}
                anchor='left'
                sx={{
                    width: drawerWidth,
                    flexShrink: 0,
                    '& .MuiDrawer-paper': {
                        width: drawerWidth,
                        boxSizing: 'border-box',
                        height: `calc(100% - ${appbarHeight}px)`,
                        marginTop: `${appbarHeight}px`,
                        zIndex: -1
                    },
                }}
                PaperProps={{
                    elevation: 5,
                    sx: {
                        backgroundColor: 'primary',
                    },
                }}
            >
                <Divider/>
                <List>
                    {/*<ListItemButton>*/}
                    <ListItem>
                        <ListItemText primary="Registries"/>
                    </ListItem>
                    {/*{showRegistries ? <ExpandLess/> : <ExpandMore/>}*/}
                    {/*</ListItemButton>*/}
                    <Divider/>
                    <Collapse in={showRegistries} timeout="auto" unmountOnExit>
                        {registries.map((registry) => {
                            const color = registry.status === 'active' ? 'primary' : 'disabled'

                            let RegistryFolderIcon = () => {
                                if (registry.status === 'inactive') return <FolderOff color={color}/>

                                return registry.invitation ? <CreateNewFolder color={color}/> : <Folder color={color}/>
                            }

                            return (
                                <ListItemButton
                                    key={registry.name}
                                    onClick={() => handleRegistryClick(registry.name)}
                                    selected={selectedRegistry === registry.name}
                                >
                                    <ListItemIcon>
                                        <RegistryFolderIcon/>
                                    </ListItemIcon>
                                    <ListItemText primary={registry.name}
                                                  secondary={registry.invitation ? `${registry.role} (Invited)` : registry.role}
                                    />
                                </ListItemButton>
                            )
                        })}
                    </Collapse>
                </List>
            </Drawer>
            <Box sx={{
                width: `calc(100% - ${drawerWidth}px)`,
                marginLeft: `${drawerWidth}px`,
                minHeight: `calc(100vh - ${appbarHeight}px)`,
                display: 'flex',
                backgroundColor: Tokens.backgroundColor,
                zIndex: -1,
                position: 'absolute',
                top: `${Tokens.AppBarHeight}px`,
            }}
            >
                <Outlet/>
            </Box>
        </Box>
    )
}