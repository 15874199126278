import React from 'react';
import {useMutation, useQuery} from "react-query";

import {
    getRegistryUsage,
    postSubscribeRegistry
} from "../../api";
import {HumanFileSize} from "../../libs/filesizes";
import { useParams} from "react-router-dom";
import {
    Box,
    CircularProgress,
    Divider,
    Paper,
    Stack,
    Typography
} from "@mui/material";
import {CircularProgressWithLabel} from "../../components/CircularProgressWithLabel";

interface IOverview {
    role: string;
}

export const Usage: React.FC<IOverview> = ({role}) => {
    const params = useParams()
    const selectedRegistry = params['registry_name'] ?? ''
    const registryUsageQuery = useQuery({
        queryKey: 'registry-usage:' + selectedRegistry,
        queryFn: getRegistryUsage(selectedRegistry)
    });

    const subscribeRegistryMutation = useMutation({
        mutationFn: async () => {
            const idempotencyKey = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15)

            return postSubscribeRegistry(selectedRegistry, "free", idempotencyKey)
        },
        onSuccess: (res) => {
            window.location.href = res.url
        },
    });

    if (registryUsageQuery.isLoading) {
        return (
            <CircularProgress/>
        )
    }

    if (registryUsageQuery.isError || !registryUsageQuery.isSuccess) {
        return <div>error</div>
    }


    const {registryUsage} = registryUsageQuery.data
    const packageUsedPercent = (registryUsage.packagesUsed / registryUsage.allowedPackages) * 100
    const bytesUsedPercent = (registryUsage.bytesUsed / registryUsage.allowedBytes) * 100
    const usersUsedPercent = (registryUsage.usersUsed / registryUsage.allowedUsers) * 100

    const Usage = ({label, percentage}: any) =>  <Box>
            <Box sx={{ display: 'flex', justifyContent: 'center'}} p={2}>
            <CircularProgressWithLabel variant='determinate' value={(percentage > 0 && percentage<=1)  ? 1 : percentage} size={50}/>
            </Box>
        <Typography variant='body1'>{label}</Typography>
        </Box>

    const RegistryUsage = () => <Stack
            direction='row'
            divider={<Divider orientation='vertical' flexItem />}
            justifyContent='space-evenly'>
            <Usage label={`Packages Used ${registryUsage.packagesUsed} / ${registryUsage.allowedPackages}`} percentage={packageUsedPercent}/>
            <Usage label={`Total Space Used: ${HumanFileSize(registryUsage.bytesUsed)} / ${HumanFileSize(registryUsage.allowedBytes)}`} percentage={bytesUsedPercent}/>
            <Usage label={`Team Members: ${registryUsage.usersUsed} / ${registryUsage.allowedUsers}`} percentage={usersUsedPercent}/>
        </Stack>


    return (
        <Paper sx={{padding: 3}}>
            <Typography variant='h4' gutterBottom>Usage</Typography>
            <Divider/>
            {registryUsageQuery.isLoading ?
                <Box sx={{display: 'flex'}}><CircularProgress sx={{margin: 'auto'}} /></Box>
            : <RegistryUsage/>
            }
        </Paper>
    )
}