import './index.css';

import React from 'react';
import ReactDOM from 'react-dom/client';
import {QueryClient, QueryClientProvider} from "react-query";
import {createTheme} from '@mui/material/styles';
import {ThemeProvider} from "@mui/material";
import {BrowserRouter, Navigate, Route, Routes} from "react-router-dom";

import reportWebVitals from './reportWebVitals';
import {NonAuthLayout} from "./layouts/NonAuthLayout";
import {SignIn} from "./pages/SignIn";
import AuthProvider, {AuthIsNotSignedIn, AuthIsSignedIn} from "./contexts/authContext";
import {SignUp} from "./pages/SignUp";
import {Verify} from "./pages/Verify";
import {Profile} from "./pages/Profile";
import {Registry} from "./pages/Registry";
import {SignedOut} from "./pages/SignedOut";
import {ForgotPassword} from "./pages/ForgotPassword";
import {Team} from "./pages/Registry/Team";
import {Packages} from "./pages/Registry/Packages";
import {Usage} from "./pages/Registry/Usage";
import {ConfirmPasswordReset} from "./pages/ConfirmPasswordReset";
import {Dashboard} from "./pages/Dashboard";
import {PackageDetails} from "./pages/Registry/PackageDetails";
import {CreateRegistry} from "./pages/CreateRegistry";
import {LoadingPage} from "./LoadingPage";
import {Settings} from "./pages/Registry/Settings";

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

const theme = createTheme({
    typography: {
        fontFamily: [
            'Arial',
        ].join(','),
    },
    palette: {
        primary: {
            main: '#20358d',
        },
        secondary: {
            main: '#836470',
        },
        background: {
            default: '#ececec',
        }
    },
    components: {
        MuiAppBar: {
            styleOverrides: {
                colorPrimary: {
                    backgroundColor: '#fff',
                }
            }
        }
    }
});


const SignInRoutes: React.FC = () => <BrowserRouter>
    <Routes>
        <Route path="/confirm-password-reset" element={<ConfirmPasswordReset/>}/>
        <Route path="/" element={<NonAuthLayout/>}>
            <Route path="/signin" element={<SignIn/>}/>
            <Route path="/signup" element={<SignUp/>}/>
            <Route path="/signup-invited" element={<SignUp/>}/>
            <Route path="/signout" element={<SignedOut/>}/>
            <Route path="/verify" element={<Verify/>}/>
            <Route path="/forgot-password" element={<ForgotPassword/>}/>
            <Route path="" element={<Navigate to="/signin" replace/>}/>
        </Route>
        <Route path="*" element={<Navigate to="/signin" replace/>}/>
    </Routes>
</BrowserRouter>

const MainRoutes: React.FC = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<LoadingPage/>}>
                    <Route path="/confirm-password-reset" element={<ConfirmPasswordReset/>} />
                    <Route path="/" element={<Dashboard/>}>
                        <Route path="registry" element={<Registry/>}>
                            <Route path=":registry_name" element={<Packages/>}/>
                            <Route path=":registry_name/packages/:package_name" element={<PackageDetails/>}/>
                            <Route path=":registry_name/overview" element={<Usage role={'owner'}/>}/>
                            <Route path=":registry_name/team-members" element={<Team/>}/>
                            <Route path=":registry_name/settings" element={<Settings/>}/>
                        </Route>
                        <Route path="/create/registry" element={<CreateRegistry/>}/>
                        <Route path="*" element={<Navigate to="/" replace/>}/>
                    </Route>
                    <Route path="account" element={<Profile/>}/>
                </Route>
            </Routes>
        </BrowserRouter>
    )
}

const queryClient = new QueryClient()
queryClient.setDefaultOptions({
    queries: {
        refetchOnWindowFocus: true,
        staleTime: 1000 * 60 * 5 // 5 minutes,
    },
})

root.render(
    <React.StrictMode>
        <AuthProvider>
            <ThemeProvider theme={theme}>
                <QueryClientProvider client={queryClient}>
                    <AuthIsSignedIn>
                        <MainRoutes/>
                    </AuthIsSignedIn>
                    <AuthIsNotSignedIn>
                        <SignInRoutes/>
                    </AuthIsNotSignedIn>
                </QueryClientProvider>
            </ThemeProvider>
        </AuthProvider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
