import React, {useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import {Formik, Form, Field} from "formik";
import {TextField} from 'formik-mui'
import * as Yup from 'yup';

import {
    Box,
    Typography,
    Button,
    Stack,
    Container,
    Paper,
    LinearProgress,
    InputAdornment
} from "@mui/material";
import {ErrorSharp, Lock} from "@mui/icons-material";

import {useQueryString} from "../hooks/useQueryString";
import {Tokens} from "../tokens/tokens";
import Turnstile from "react-turnstile";
import {useMutation} from "react-query";
import {postConfirmUserPasswordReset, TurnstileSiteKey} from "../api";


const ConfirmPasswordSchema = Yup.object().shape({
    password: Yup.string()
        .required('Password is required')
        .min(8, 'Password must be 8 or more characters')
        .max(256, 'Password cannot be longer than 256 characters'),
    confirmPassword: Yup.string().required('Confirm Password is required').oneOf([Yup.ref('password')], 'Passwords must match')
})

const PasswordResetComponent: React.FC = () => {
    const [cognitoError, setCognitoError] = useState('');
    const [resetSuccessfully, setResetSuccessfully] = useState(false);
    const [turnstileToken, setTurnstileToken] = useState('')

    const navigate = useNavigate();
    const query = useQueryString();

    const username = query.get(decodeURIComponent('username'));
    const code = query.get(decodeURIComponent('code'));

    const postConfirmPasswordMutation = useMutation({
        mutationFn: async (new_password: string) => {
            return postConfirmUserPasswordReset(turnstileToken, username ?? '', code ?? '', new_password)
        },
        onSuccess: () => {
            setResetSuccessfully(true)
        }
    });

    if (!username || !code) {
        setTimeout(() => {
            navigate('/signin')
        }, 3000);

        return (
            <>
                <Typography fontWeight='bold' gutterBottom>Invalid Reset Password Link</Typography>
                <ErrorSharp fontSize='large' color='error'/>
                <Typography gutterBottom mb='20px'>The reset password link is not valid. You will be redirected in 3
                    seconds.</Typography>
                <Typography variant='subtitle1'><Link to='/signin'>Click here if redirection fails</Link></Typography>
            </>
        )
    }

    if (resetSuccessfully) {
        setTimeout(() => {
            navigate('/signin')
        }, 3000);

        return (
            <>
                <Typography fontWeight='bold' gutterBottom>Password Reset Successfully</Typography>
                <div className='text-center'><i className='mdi mdi-check mdi-48px text-success'></i></div>
                <Typography gutterBottom mb='20px'>You will be redirected in 3 seconds.</Typography>
                <Typography><Link to='/signin'>Click here if automatic redirection fails</Link>
                </Typography>
            </>
        )
    }


    return (<Formik
        initialValues={{
            password: '',
            confirmPassword: ''
        }}
        validationSchema={ConfirmPasswordSchema}
        onSubmit={({password}, {setSubmitting}) => {
            postConfirmPasswordMutation.mutate(password)
        }}>
        {({
              submitForm,
              dirty,
              isValid,
              isSubmitting
          }) => (
            <Form>
                <Typography fontSize='large' fontWeight='bold' gutterBottom>Confirm Password Reset</Typography>
                <Typography variant='body1' gutterBottom>Password must be at least 8 characters long, include symbols
                    and a mix
                    of upper / lowercase characters</Typography>
                <Stack spacing={3} sx={{justifyContent: 'center'}}>
                    <Field
                        component={TextField}
                        label='New Password'
                        type='password'
                        name='password'
                        placeholder='Enter new password'
                        InputProps={{
                            startAdornment: <InputAdornment position='start'><Lock/></InputAdornment>
                        }}
                    />

                    <Field
                        component={TextField}
                        label='Confirm Password'
                        type='password'
                        name='confirmPassword'
                        placeholder='Confirm Password'
                        InputProps={{
                            startAdornment: <InputAdornment position='start'><Lock/></InputAdornment>
                        }}
                    />
                    {cognitoError && <Box className='text-danger-emphasis text-center'>
                        <i className='mdi mdi-alert mdi-36px text-center'></i>
                        {cognitoError}
                    </Box>}
                    {isSubmitting && <LinearProgress/>}
                    <Box>
                        <Button variant='contained' disabled={!dirty || !isValid || !turnstileToken || isSubmitting}
                                sx={{
                                    borderRadius: '0px',
                                    padding: '10px',
                                    width: '50%'
                                }}
                                onClick={submitForm}>Reset</Button>
                    </Box>
                    <Turnstile sitekey={TurnstileSiteKey}
                               action={'confirm-reset-password'}
                               onVerify={token => {
                                   setTurnstileToken(token)
                               }}/>
                </Stack>
            </Form>
        )}
    </Formik>)
}


export const ConfirmPasswordReset = () => <Box sx={{
    textAlign: 'center',
    backgroundColor: 'rgb(239, 242, 247)',
    minHeight: '100vh',
    height: '100%',
    display: 'flex',
}}>
    <Container sx={{
        display: 'flex',
        justifyContent: 'center',
        marginY: 'auto',
    }}>
        <Box sx={{
            margin: 'auto',
        }}>
            <Box sx={{
                display: 'inline-flex',
                width: '25%',
                padding: '20px',
            }}>
                <img src={Tokens.LogoDark} alt='PrivateUPM Logo' width='100%' draggable={false}/>
            </Box>
            <Paper elevation={3} sx={{
                padding: '60px',
                margin: 'auto',
                width: '340px',
            }}>
                <PasswordResetComponent/>
            </Paper>
        </Box>
    </Container>
</Box>
