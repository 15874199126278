import React from 'react';
import {
    Box,
    Button,
    Dialog, DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Divider, LinearProgress,
    Stack,
    Typography
} from "@mui/material";


type Props = {
    open: boolean;
    onCancel: () => void,
    onConfirm: () => void,
    title: string,
    description: string,
    confirmText: string,
    loading?: boolean
}

export const SimpleDialog = (props: Props) => {
    const {
        open,
        onCancel,
        title,
        onConfirm,
        confirmText,
        loading,
    description } = props;
    return (
        <Dialog open={open}>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent dividers>
                <DialogContentText>{description}</DialogContentText>
            </DialogContent>
            { loading && <LinearProgress/>}
            <DialogActions>
                <Button disabled={loading} color='error' onClick={onCancel}>Cancel</Button>
                <Button disabled={loading} autoFocus onClick={onConfirm}>{confirmText}</Button>
            </DialogActions>
        </Dialog>
    )
}