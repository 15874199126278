import React, {useContext, useState} from 'react'
import {Link as RouterLink, useNavigate} from "react-router-dom"
import {Formik, Form, Field} from "formik"
import {TextField} from 'formik-mui'
import * as Yup from 'yup'
import {
    Box,
    Checkbox,
    FormControlLabel,
    InputAdornment,
    Stack,
    Typography,
    Button,
    Link, FormGroup, LinearProgress
} from "@mui/material";
import {Lock, Email} from '@mui/icons-material';


import {AuthContext} from "../contexts/authContext";

const SignInSchema = Yup.object().shape({
    email: Yup.string()
        .trim()
        .max(200)
        .email('A valid email must be provided')
        .required('Email is required'),
    password: Yup
        .string()
        .min(1)
        .required('Password is required')
})

export const SignIn: React.FC = () => {
    const authContext = useContext(AuthContext);
    const navigate = useNavigate()
    const [error, setError] = useState(null)

    const signInClicked = async (email: string, password: string) => {
        try {
            await authContext.signInWithEmail(email, password)
            navigate('/')
        } catch (err: any) {
            if (err.code === 'UserNotConfirmedException') {
                navigate('/verify')
            } else {
                setError(err.message)
            }
        }
    }

    return (
        <Formik
            initialValues={{
                email: '',
                password: ''
            }}
            onSubmit={async (values, {setSubmitting}) => {
                try {
                    await signInClicked(values.email, values.password)
                } catch (err: any) {
                    // TODO: Handle error
                } finally {
                    setSubmitting(false)
                }
            }}
            validationSchema={SignInSchema}
        >{({
               submitForm,
               dirty,
               isSubmitting,
               isValid
           }) => (
            <Form>
                <FormGroup>
                    <Stack spacing={3} sx={{justifyContent: 'center'}}>
                        <Typography variant='h4'>Welcome!</Typography>
                        <Typography variant='subtitle1' gutterBottom>Sign in to use PrivateUPM</Typography>
                        <Field
                            component={TextField}
                            label='Email'
                            name='email'
                            type='email'
                            placeholder='Enter Email Address'
                            InputProps={{
                                startAdornment: <InputAdornment position='start'><Email/></InputAdornment>
                            }}/>

                        <Field
                            component={TextField}
                            label='Password'
                            name='password'
                            type='password'
                            placeholder='Enter Password'
                            InputProps={{
                                startAdornment: <InputAdornment position='start'><Lock/></InputAdornment>
                            }}/>
                        <FormControlLabel control={<Checkbox/>} label="Remember Me"/>
                        {isSubmitting && <LinearProgress/>}
                        {error && <Typography variant='body1' color='error'>{error}</Typography>}
                        <Box sx={{
                            margin: 'auto',
                        }}>
                            <Button variant='contained' sx={{
                                borderRadius: '0px',
                                width: '50%',
                                padding: '10px',
                            }}
                                    type='submit'
                                    onClick={submitForm}
                                    disabled={!dirty || !isValid || isSubmitting}
                            >Sign In</Button>
                        </Box>
                        <Typography variant='button'><Link component={RouterLink} to={'/forgot-password'}>Forgot
                            Password?</Link></Typography>
                        <Typography>Don't have an account? <Typography variant='button'><Link component={RouterLink}
                                                                                              to={'/signup'}>Sign
                            Up</Link></Typography></Typography>
                    </Stack>
                </FormGroup>
            </Form>
        )}
        </Formik>
    )
}