import React from "react";

import {useQuery} from "react-query";
import {getDashboard} from "./api";
import {Outlet, useLocation, useNavigate} from "react-router-dom";
import {Box, CircularProgress} from "@mui/material";
import {Tokens} from "./tokens/tokens";


const redirectToFirstRegistryMap: any = {
    "/": true,
    "/registry": true,
    "/registry/": true,
}

export const LoadingPage = () => {
    const getDashboardQuery = useQuery('dashboard', getDashboard, {})
    const navigate = useNavigate();
    const location = useLocation();

    if (getDashboardQuery.isLoading) {
        return <LoadingScreen/>
    }

    const registries = getDashboardQuery.data?.registries ?? []
    if (registries.length > 0 && redirectToFirstRegistryMap[location.pathname] === true) {
        navigate(`/registry/${registries[0].name}`)
    }

    if (registries.length === 0 && location.pathname === '/') {
        navigate(`/registry`)
    }
    return <Outlet/>
}


const LoadingScreen = () => {
    return <Box sx={{backgroundColor: Tokens.backgroundColor, height: '100vh', display: 'flex'}}>
        <CircularProgress sx={{margin: 'auto'}} size={80} variant='indeterminate'/>
    </Box>
}