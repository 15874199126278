import React, {useContext, useEffect, useRef, useState} from 'react'
import {Link as RouterLink, useNavigate} from "react-router-dom";

import {
    Container, CircularProgress,
    Box, Paper, Typography,
    Link
} from '@mui/material'
import {CheckCircleOutline} from "@mui/icons-material";


import {useQueryString} from "../hooks/useQueryString";
import logoLight from "../assets/images/privateupm-logo.png";
import {AuthContext} from "../contexts/authContext";
import {useMutation} from "react-query";
import {postConfirmUserRequest} from "../api";


export const Verify: React.FC = () => {
    const [isVerified, setIsVerified] = useState(false)
    const isFetching = useRef(false)
    const [email, setEmail] = useState(null)

    const query = useQueryString();
    const username = query.get(decodeURIComponent('username')) ?? '';
    const code = query.get(decodeURIComponent('code')) ?? '';

    const confirmMutation = useMutation({
        mutationFn: async () => {
            return postConfirmUserRequest({
                code,
                username
            })
        },
        onSuccess: () => {
            setIsVerified(true)
            isFetching.current = false;
        }
    })

    // States:
    // Initial
    // Verifying
    // Verified | InvalidVerificationLink | VerificationError

    useEffect(() => {
        if (isVerified) return;
        if (!isFetching.current) verifyCode()
    }, []);

    const authContext = useContext(AuthContext);
    const navigate = useNavigate();

    const verifyCode = async () => {
        try {
            isFetching.current = true
            await confirmMutation.mutate()

        } catch (err) {
            // TODO: Catch invalid verification link
            // setError('Invalid Code')
        }
    }

    if (!username || !code) {
        setTimeout(() => {
            navigate('/signin')
        }, 3000);

        return (
            <Box>
                <Typography variant='h5' gutterBottom fontSize='normal'>Invalid Verification Link</Typography>
                <Typography variant='body1' mt={2}>You will be redirected in 3 seconds</Typography>
                <Typography mt={3}>
                    <Link component={RouterLink} to={"/signin"}>Click
                        here if automatic
                        redirection
                        fails</Link>
                </Typography>
            </Box>)
    }

    const Verified = () => {
        setTimeout(() => {
            navigate(email ? '/signin?email=' + encodeURIComponent(email) : "/signin")
        }, 3000);

        return (<>
            <Typography variant='body1'>Verified!</Typography>
            <CheckCircleOutline color='success' fontSize='large'/>
            <Typography variant='body2'>You will be redirected in 3 seconds.</Typography>
            <Typography>
                <Link component={RouterLink} to={email ? '/signin?email=' + encodeURIComponent(email) : "/signin"}>Click
                    this link if
                    redirection
                    fails</Link></Typography>
        </>)
    }

    const Verifying = () => <>
        <Typography mb='20px' variant='body1'>Verifying</Typography>
        <CircularProgress/>
    </>

    // TODO: Instead of a bool use an enum to store store the state of verification
    // We can use Verified, Verifying, InvalidVerificationLink and VerificationError with a link to resend the verification email

    if (isVerified) return <Verified/>

    return <Verifying/>
}