import React from 'react';
import {Link} from "react-router-dom";
import {Box, Button, Typography} from "@mui/material";

export const SignedOut = () => {
    return (
        <>
            <Box mb='20px'>
                <Typography variant='h6'>Signed Out</Typography>
                <Typography variant='subtitle2'>Thank you for using PrivateUPM</Typography>
            </Box>
            <Link to='/signin'>
                <Button variant='contained' sx={{
                    borderRadius: '0px',
                    width: '100%',
                    padding: '10px',
                }}>Sign In</Button>
            </Link>
        </>
    )
}